






import { AppColorSchema } from "@/lib/utility/AppColorSchema";
import { ReportScreenEnum } from "@/store/enum/partner/report-screen.enum";
import { ConfigModule } from "@/store/modules/config";
import { ReportModule } from "@/store/modules/report.store";
import { Component, Vue } from "vue-property-decorator";

@Component({ components: {} })
export default class V extends Vue {
  get loading() {
    return ReportModule.loading;
  }

  async mounted() {
    const partnerName = "mrfiktiv";
    new AppColorSchema(this.$vuetify, ConfigModule.damageReportColorSchema);
    ReportModule.setPartnerName(partnerName);
    await ReportModule.getPartnerByName(partnerName);
    this.trySetStepperByPath();
  }

  trySetStepperByPath() {
    const route = this.$route.path;
    const parts = route.split("/"); // "/3" -> ["", "3" ]
    const maybeAStep: string = parts[1];
    if (Object.values(ReportScreenEnum).includes(maybeAStep as ReportScreenEnum)) {
      ReportModule.setStep(maybeAStep as ReportScreenEnum);
    }
  }
}

import Vue from "vue";
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
import VueLogger from "vuejs-logger";
import vuetify from "../plugins/VuetifyPlugin";
import "../registerServiceWorker";
import router from "../router/report.router";
import store from "../store/VuexPlugin";
import mrfiktiv from "./mrfiktiv.vue";
import { UIHelper } from "../lib/UIHelper";
import { ConfigModule } from "@/store/modules/config";
import { abilitiesPlugin, Can } from "@casl/vue/";
import { UserModule } from "@/store/modules/me-user.store";
import i18n from "../plugins/I18nPlugin";
import VueAppInsights from "../plugins/AppInsightsPlugin";

const isProduction = process.env.NODE_ENV === "production";

const options = {
  isEnabled: true,
  logLevel: isProduction ? "error" : "debug",
  stringifyArguments: false,
  showLogLevel: true,
  showMethodName: true,
  separator: "|",
  showConsoleColors: true
};

// link for toast-notification doc: https://maronato.github.io/vue-toastification/
const toastOptions = {
  position: "bottom-right"
};

Vue.use(Toast, toastOptions);

Vue.use(VueLogger as any, options);

Vue.use(UIHelper);

Vue.use(abilitiesPlugin, UserModule.abilities);

Vue.component("Can", Can);

Vue.config.productionTip = false;

vuetify.framework.theme.dark = ConfigModule.darkMode;

const url = ConfigModule.mrfiktivBaseUrl + "/config";
Vue.use(VueAppInsights, {
  baseName: "mrfiktiv",
  url: url,
  id: ConfigModule.appInsightsKey,
  cookie: false, // disable by default
  router
});

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: h => h(mrfiktiv)
}).$mount("#report");
